import { defineMessages } from 'react-intl';

export default defineMessages({
  OK: { id: 'global.ok', defaultMessage: 'OK' },
  TO_CONFIRM: { id: 'global.confirm', defaultMessage: 'To Confirm' },
  CANCEL: { id: 'global.cancel', defaultMessage: 'Cancel' },
  BACK: { id: 'global.back', defaultMessage: 'Back' },
  SUCCESS: { id: 'global.success', defaultMessage: 'Success' },
  VALIDATE: { id: 'global.validate', defaultMessage: 'Validate' },
  DELETE: { id: 'global.delete', defaultMessage: 'Delete' },
  PRINT: { id: 'global.print', defaultMessage: 'Print' },
  USER: { id: 'global.user', defaultMessage: 'User' },
  ERROR_TITLE: { id: 'global.error.title', defaultMessage: 'Something went wrong!' },
  WARNING_TITLE: { id: 'global.warning.title', defaultMessage: 'Warning !' },
  ERROR_ACCESS_TITLE: { id: 'global.error.access.title', defaultMessage: 'Access forbidden !' },
  ERROR_DESCRIPTION: { id: 'global.error.description', defaultMessage: 'The application has encountered an unknown error. Please try to refresh the application. If the problem persists, please contact your administrator.' },
  ERROR_NO_TICKET_TO_BOOK_DESCRIPTION: { id: 'global.error.noticket.toprepare.description', defaultMessage: 'This EAN doesn\'t correspond to the product to be prepared' },
  ERROR_NO_TICKET_DESCRIPTION: { id: 'global.error.noticket.description', defaultMessage: 'No ticket to prepare for this product' },
  ERROR_TICKET_NOT_PRINT_DESCRIPTION: { id: 'global.error.ticket.no.ok.ko', defaultMessage: 'The ticket is not printable' },
  ERROR_NOT_YOUR_TICKET_PRINT_DESCRIPTION: { id: 'global.error.not.your.ticket', defaultMessage: 'This ticket is already being processed' },
  ERROR_IP_DESCRIPTION: { id: 'global.error.ip.description', defaultMessage: 'The ip address is incorrect' },
  ERROR_IMEI_DESCRIPTION: { id: 'global.error.imei.description', defaultMessage: 'The imei doesn\'t respect the standards' },
  ERROR_IMEI_SCAN_DESCRIPTION: { id: 'global.error.imei.scan.description', defaultMessage: 'No ticket to prepare for this IMEI' },
  ERROR_IMEI_SCANNED_DESCRIPTION: { id: 'global.error.imei.scanned.description', defaultMessage: 'The imei has already been scanned' },
  ERROR_IMEI_NEED: { id: 'global.error.imei.need', defaultMessage: 'Need to scan an imei' },
  ERROR_WITHDRAWAL_NOT_FINISH: { id: 'global.error.withdrawal.not.finish', defaultMessage: 'At least one ticket has not been processed' },
  ERROR_WITHDRAWAL_NOT_FINISH_COUNT: { id: 'global.error.withdrawal.not.finish.count', defaultMessage: 'There are {num} tickets left to process' },
  ERROR_NOT_AUTHORIZED_CHANGE_SITE: { id: 'global.error.not.authorized.change.site', defaultMessage: 'You are not allowed to change site' },
  TICKET_NOT_RELEASED_DESCRIPTION: { id: 'tickets.modal.not.released', defaultMessage: 'Please note that the ticket is not released.' },
  SETTINGS_NOT_SAVED_DESCRIPTION: { id: 'views.settings.not.saved', defaultMessage: 'Preferences have not been saved' },
  CHANGE_SITE_SUCCESS_DESCRIPTION: { id: 'temp.site.success.title', defaultMessage: 'You are currently connected to site <bold>{siteId} - {siteName}</bold>. The supervisors of the site have been informed.' },
  CHANGE_SITE_ERROR_DESCRIPTION: { id: 'temp.site.error.title', defaultMessage: 'Cannot connect to site' },

  TITLE: { id: 'global.title', defaultMessage: 'e-ticket' },

  REQUIRED_FIELD: { id: 'form.error.required', defaultMessage: 'This field is required' },

  EAN: { id: 'ticket.ean', defaultMessage: 'EAN' },
  SAPID: { id: 'ticket.sapid', defaultMessage: 'Ref. {sapId}' },

  REASON: { id: 'global.reason', defaultMessage: 'Reason :' },
  SMS_SEND: { id: 'global.sms.send', defaultMessage: 'SMS send to the client' },
  WITHDRAWAL_TICKET: { id: 'global.withdrawal.ticket.ok', defaultMessage: 'Product to be deposited at a withdrawal point' },
});
