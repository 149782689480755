import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
} from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { isAuthenticated } from 'services/auth';
import userManager from 'services/auth/userManager';
import { hide as hideSplashScreen } from 'services/splash';
import globalMessages from 'intl/messages/global.messages';

import { selectUser } from 'redux/auth/selectors';
import { fetchUserData } from 'redux/userData/actions';
import LoadingScreen from './generic/loading/loading-screen/LoadingScreen';
import DevToolsProvider from './generic/devtools/core/context/provider/lazy';
import DevToolsUi from './generic/devtools/core/ui';
import LoadDevTool from './generic/devtools/core/actions/load/LoadDevTool';

import routes from './routes';
import notify, { NOTIFICATION_TYPES } from './generic/notification/notification';
import { fetchUserPreferences } from '../redux/userPreferences/actions';

const Tickets = lazy(() => import('./views/tickets/TicketsView'));
const TicketPreparation = lazy(() => import('./views/ticketPreparation/TicketPreparationView'));
const TicketPreparationWithdrawalView = lazy(() => import('./views/ticketPreparationWithdrawal/TicketPreparationWithdrawalView'));
const UserDataView = lazy(() => import('./views/userData/UserDataView'));
const NotFound = lazy(() => import('./views/error/not-found/NotFoundView'));
const Login = lazy(() => import('./views/login/LoginView'));
const Logout = lazy(() => import('./views/logout/LogoutView'));

const App = () => {
  const location = useLocation();
  const user = useSelector(selectUser);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isUserPreferencesLoaded, setIsUserPreferencesLoaded] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    hideSplashScreen();
  }, []);

  useEffect(() => {
    if (user && !isUserPreferencesLoaded) {
      dispatch(fetchUserPreferences(user?.profile.employee_id)).then(() => {
        setIsUserPreferencesLoaded(true);
      });
    }
  }, [user, isUserPreferencesLoaded]);

  useEffect(() => {
    if (user) {
      dispatch(fetchUserData(user?.profile.employee_id, user?.profile.name)).then(() => {
        setIsUserLoaded(true);
      });
    }
  }, [user]);

  /* eslint-disable react/prop-types */
  const isAuthorized = RedirectComponent => () => {
    if (!isAuthenticated()) {
      if (window.location.search.includes('error')) {
        return userManager.signoutRedirect();
      }

      localStorage.setItem('pathBeforeLogin', location.pathname);
      return userManager.signinRedirect();
    }

    return <RedirectComponent />;
  };

  const canChangeSite = RedirectComponent => () => {
    if (isAuthenticated() && (user?.scope || []).includes('bl:app.eticket:multisite')) {
      return <RedirectComponent />;
    }

    notify({
      type: NOTIFICATION_TYPES.ERROR,
      message: intl.formatMessage(globalMessages.ERROR_ACCESS_TITLE),
      description: intl.formatMessage(globalMessages.ERROR_NOT_AUTHORIZED_CHANGE_SITE),
    });

    return <Redirect to={routes.HOME.ROOT} />;
  };

  return (
    <Suspense fallback={<LoadingScreen />}>
      <DevToolsProvider>
        <Switch replace>
          { isUserLoaded && (
            <Route
              path={[routes.HOME.ROOT, routes.HOME.LINK]}
              exact
              render={isAuthorized(Tickets)}
            />
          )}

          { isUserLoaded && (
            <Route
              path={routes.TICKET.ROOT}
              exact
              render={isAuthorized(TicketPreparation)}
            />
          )}

          { isUserLoaded && (
            <Route
              path={routes.ORDER.ROOT}
              exact
              render={isAuthorized(TicketPreparationWithdrawalView)}
            />
          )}

          { isUserLoaded && (
            <Route
              path={routes.USER.ROOT}
              exact
              render={canChangeSite(UserDataView)}
            />
          )}

          <Route
            path={routes.AUTH.LOGIN}
            exact
            component={Login}
          />

          <Route
            path={routes.AUTH.LOGOUT}
            exact
            component={Logout}
          />

          {
            isUserLoaded
              ? <Route component={NotFound} />
              : <Route component={isAuthorized(LoadingScreen)} />
          }
        </Switch>
        <DevToolsUi />
        <LoadDevTool name="intl/LanguageSelector" />
      </DevToolsProvider>
    </Suspense>
  );
};

export default App;
